var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texture-background-beige"},[_vm._m(0),_c('div',{staticClass:"context-container mt-4"},[_vm._m(1),_vm._l((_vm.main_paragraphs),function(paragraph){return _c('p',{key:paragraph.id,staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(paragraph, { 
				'“NLSE+”': '“NLSE+”', 
				'“Agreement”': '“Agreement”', 
				'“You”': '“You”', 
				'“you”': '“you”', 
				'“your”': '“your”', 
				'“Users”': '“Users”', 
				'“Content”': '“Content”', 
				'“Platform”': '“Platform”', 
				'“Services”': '“Services”', 
				'“Effective Date”': '“Effective Date”' 
			}))}})}),_c('h2',{staticClass:"context-title"},[_vm._v("1. DEFINITIONS")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.definitions.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("2. TERM")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.terms.paragraph)+" "),_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.terms.listItems),function(listItem){return _c('li',{key:listItem.id,staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(listItem.item_title))]),_vm._v("  "),_c('span',{staticStyle:{"font-size":"1.35rem"},domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(listItem.item_description, 
						{
							'“Subscription Election”': '“Subscription Election”',
							'“Paid Subscriptions”': '“Paid Subscriptions”',
							'“Trial Subscription”': '“Trial Subscription”',
							'“Trial Period”': '“Trial Period”',
							'“Trial Termination Notice”': '“Trial Termination Notice”',
							'“Monthly Paid Subscription”': '“Monthly Paid Subscription”',
							'“Annual Paid Subscription”': '“Annual Paid Subscription”'
						},
						{
							'plus.nlse.com/account': 'https://plus.nlse.com/account'
						}
						))}})])}),0)]),_c('h2',{staticClass:"context-title"},[_vm._v("3. MODIFICATIONS")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.modifications.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("4. ELIGIBILITY")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.eligibility.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("5. ACCOUNTS")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.accounts),function(listItem){return _c('li',{key:listItem.id,staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(listItem.item_title))]),_vm._v("  "),_c('span',{staticStyle:{"font-size":"1.35rem"},domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
							listItem.item_description, 
							{
								'“Account”': '“Account”',
								'“Account ID”': '“Account ID”',
								'“Account Information”': '“Account Information”'
							}
						))}})])}),0)]),_c('h2',{staticClass:"context-title"},[_vm._v("6. ACCESS")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.access),function(listItem){return _c('li',{key:listItem.id,staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(listItem.item_title))]),_vm._v("  "),_c('span',{staticStyle:{"font-size":"1.35rem"},domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
							listItem.item_description, 
							{
								'“Site”': '“Site”',
								'“Application”': '“Application”',
								'“Platform Content”': '“Platform Content”'
							},
							{
								'nlseplussupport@nlse.com': 'mailto:nlseplussupport@nlse.com'
							}
						))}})])}),0)]),_c('h2',{staticClass:"context-title"},[_vm._v("7. YOUR CONTENT")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.your_content.paragraph,
				{
					'“Your Content”': '“Your Content”',
					'“Use”': '“Use”'
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("8. DATA PRIVACY")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.data_privacy),function(listItem){return _c('li',{key:listItem.id,staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(listItem.item_title))]),_vm._v("  "),_c('span',{staticStyle:{"font-size":"1.35rem"},domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
							listItem.item_description, 
							{
								'“Privacy Policy”': '“Privacy Policy”',
							}
						))}})])}),0)]),_c('h2',{staticClass:"context-title"},[_vm._v("9. FEES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.fees.paragraph,
				{
					'“Fees”': '“Fees”',
				},
				{
					'plus.nlse.com/accounts': 'https://plus.nlse.com/account'
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("10. PRICING CHANGES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.pricing_changes.paragraph,
				null,
				{
					'plus.nlse.com/accounts': 'https://plus.nlse.com/account'
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("11. TERMINATION AND EFFECT")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.termination_effect),function(listItem){return _c('li',{key:listItem.id,staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(listItem.item_title))]),_vm._v("  "+_vm._s(listItem.item_description)+" ")])}),0)]),_c('h2',{staticClass:"context-title"},[_vm._v("12. SUSPENSION")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.suspension.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("13. RESTARTING SERVICES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.restarting_services.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("14. PLATFORM TECHNOLOGY")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.platform_technology.paragraph,
				{
					'“Technology”': '“Technology”',
					'“IPR”': '“IPR”'
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("15. REPRESENTATIONS AND WARRANTIES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.representations_warranties.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("16. DISCLAIMERS")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.disclaimers),function(listItem){return _c('li',{key:listItem.id,staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(listItem.item_title))]),_vm._v("  "+_vm._s(listItem.item_description)+" ")])}),0)]),_c('h2',{staticClass:"context-title"},[_vm._v("17. INDEMNITY")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.indemnity.paragraph,
				{
					'“Indemnified Parties”': '“Indemnified Parties”',
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("18. LIMITATION ON LIABILITY")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.limitation_liability.paragraph,
				{
					'WILL  NOT': 'WILL  NOT',
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("19. CLAIMS OF INFRINGEMENT")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.claims_of_infringement.starting_paragraph)+" "),_c('ul',{staticClass:"context-desc ml-4"},_vm._l((_vm.claims_of_infringement.list_items),function(listItem){return _c('li',{key:listItem.id},[_vm._v(" "+_vm._s(listItem)+" ")])}),0),_c('span',{staticStyle:{"font-size":"1.35rem"},domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
					_vm.claims_of_infringement.ending_paragraph,
					{
						'“DMCA”': '“DMCA”'
					}
				))}})]),_c('h2',{staticClass:"context-title"},[_vm._v("20. DISPUTES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc",domProps:{"innerHTML":_vm._s(_vm.applyBoldTag(
				_vm.disputes.paragraph,
				{
					'“Dispute”': '“Dispute”',
					'“JAMS”': '“JAMS”',
					'“Rules”': '“Rules”',
					'“Initial Period”': '“Initial Period”',
				}
			))}}),_c('h2',{staticClass:"context-title"},[_vm._v("21. GOVERNING LAW AND VENUE")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.governing_law_venue.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("22. NOTICES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.notices.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("23. LINKED SITES")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.linked_sites.paragraph)+" ")]),_c('h2',{staticClass:"context-title"},[_vm._v("24. ADDITIONAL TERMS")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"context-desc"},[_vm._v(" "+_vm._s(_vm.additional_terms.paragraph)+" ")])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-image"},[_c('h1',{staticClass:"head-image-text"},[_vm._v("NLSE CONTENT TERMS OF SERVICE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"context-desc"},[_c('b',[_vm._v("Effective Date")]),_vm._v(": September 30, 2023 "),_c('br')])
}]

export { render, staticRenderFns }